<template>
  <label
    :for="id"
    class="checkbox-wrapper"
    :class="{ checked: value }"
  >
    <input
      :id="id"
      type="checkbox"
      class="input"
      :checked="value"
      v-bind="$attrs"
      @change="onChange"
    >

    <slot />
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    onChange: function (e) {
      this.$emit('change', e.target.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 0;
  position: relative;

  .input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    border: 1pt solid $black;
    margin-right: 16px;
    flex-shrink: 0;
  }

  &.checked::before {
    background-color: $black;
    background-image: url('../../assets/svg/checkmark.svg');
    background-size: 10px 10px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:hover,
  &:focus-within {
    color: $grey-3;

    &:not(.checked)::before {
      background-color: $grey-1;
    }
  }
}
</style>
