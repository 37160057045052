<template>
  <div class="result-block-primary">
    <b-row no-gutter>
      <b-col class="result-header">
        <h2 data-testid="resultBlockTitle">
          <slot name="heading" />
        </h2>
      </b-col>
    </b-row>
    <b-row
      no-gutter
      class="row-pad"
    >
      <b-col
        offset-lg="3"
        offset-md="0"
      >
        <button
          data-testid="resultFilterButton"
          class="filter-button d-none d-sm-inline-block d-lg-none"
          @click="onFilterButtonClick"
        >
          Filter
        </button>
        <sort-by-dropdown
          :default-selected="defaultSelected"
          class="d-inline-block sort-by-dropdown"
          @input="sortBy"
        />

        <pagination-custom
          :current-page="currentPage"
          :total-pages="pageCount"
          class="float-right d-none d-lg-inline-block"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  VBModal
} from 'bootstrap-vue'
import SortByDropdown from '../organisms/SortByDropdown'
import paginationCustom from '../molecules/PaginationCustom'

export default {
  name: 'ResultBlockPrimary',
  components: {
    BRow,
    BCol,
    SortByDropdown,
    paginationCustom
  },
  directives: {
    'b-modal': VBModal
  },
  props: {
    defaultSelected: {
      type: String,
      required: false,
      default: null
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1
    },
    resultCount: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      numberOfItemsPerPage: 10
    }
  },
  computed: {
    pageCount () {
      return Math.round(this.resultCount / this.numberOfItemsPerPage)
    }
  },
  methods: {
    sortBy (value) {
      this.$emit('sortBy', { value })
    },
    onFilterButtonClick () {
      this.$emit('showFilter')
    }
  }
}
</script>
<style scoped lang="scss">
.result-block-primary {
  h2 {
    @extend %typography-h2;

    span {
      font-weight: 400;
    }
  }

  .filter-button {
    background: $grey-1;
    border: 1px solid $grey-2;
    padding: 11px 35px;
    cursor: hover;
  }

  .row-pad {
    padding-top: 24px;
    padding-bottom: 16px;
  }

  .tablet-view {
    float: right;
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .result-block-primary {
    .sort-by-dropdown {
      float: right;
    }

    .result-header {
      padding-bottom: 10px;
    }

    .filter-button {
      padding: 11px 33px;
    }

    .row-pad {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .result-block-primary {
    .row-pad {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .tablet-view {
      float: right;
    }
  }
}
</style>
