<template>
  <ul class="filter-list">
    <li
      v-for="(type, index) in filterList"
      :key="`contentFilter#${index}${filterType}`"
      class="filter-item"
    >
      <checkbox
        :id="`contentFilter#${index}${filterType}`"
        :value="type.selected"
        data-testid="contentFilterCheckbox"
        @change="onChange(index, type.selected)"
      >
        <div class="type-label">
          <span
            class="label"
            data-testid="contentFilterLabel"
          >
            {{ type.label }}
          </span>

          <span
            class="count"
            data-testid="contentFilterCount"
          >
            {{ numberToLocaleString(type.count) }}
          </span>
        </div>

        <span class="type-label-md">
          {{ type.label }} ({{ numberToLocaleString(type.count) }})
        </span>
      </checkbox>
    </li>
  </ul>
</template>

<script>
import Checkbox from '../molecules/Checkbox'
import { numberToLocaleString } from '../../utils/FormatData'

export default {
  name: 'ContentFilterList',
  components: {
    Checkbox
  },
  props: {
    filterList: {
      type: Array,
      required: true,
      validator: function (filters) {
        const keys = ['label', 'count', 'selected']

        return filters.every(type => keys.every(key => key in type))
      }
    },
    filterType: {
      type: String,
      required: true,
      validator: (val) => ['contentTypes', 'categories'].includes(val)
    }
  },
  methods: {
    onChange: function (index, value) {
      this.$emit('change', {
        index,
        filterType: this.filterType,
        value: !value
      })
    },
    numberToLocaleString
  }
}
</script>

<style lang="scss" scoped>
.filter-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1pt solid $grey-2;
  margin-bottom: 0;

  .checkbox-wrapper {
    padding: 16px 0;
    width: 100%;
  }

  .type-label {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    &-md {
      display: none;
    }

    .count {
      margin-left: 16px;
    }
  }

  @media screen and (max-width: $breakpoint-md-max) {
    margin-bottom: 24px;
    border: none;

    .checkbox-wrapper {
      width: auto;
      padding: 0;
    }

    .type-label {
      display: none;

      &-md {
        display: block;
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm-max) {
    margin-bottom: 16px;
  }
}
</style>
