<template>
  <b-container
    class="content-filter p-0"
  >
    <b-row
      class="ctf-desktop"
    >
      <b-col
        cols="12"
        class="box"
      >
        <div class="section filter-results">
          <h3
            class="section-label"
            data-testid="contentFilterHead"
          >
            Filter Results
          </h3>

          <div class="filters-info">
            <span
              class="results-count"
              data-testid="contentFilterResultCount"
            >{{ resultCount }} results</span>

            <button
              v-if="selectedTypes.length"
              class="clear-filters-btn"
              data-testid="contentFilterClearButton"
              @click="clearFilters()"
            >
              Clear Filters
            </button>
          </div>

          <content-filter-tags
            :selected-types="selectedTypes"
            @dismiss="dismissType"
          />
        </div>

        <div
          v-if="contentTypes.length"
          class="section filter-list"
          :class="{ 'open': contentTypeOpen }"
        >
          <button
            v-b-toggle.collapse-list-content-type
            class="expand-btn"
            data-testid="contentTypeFilterToggleButton"
          >
            <h3
              class="section-label"
            >
              Content Type
            </h3>
          </button>

          <b-collapse
            id="collapse-list-content-type"
            v-model="contentTypeOpen"
          >
            <content-filter-list
              :filter-list="contentTypes"
              filter-type="contentTypes"
              data-testid="contentTypeFilterList"
              @change="onFilterChange"
            />
          </b-collapse>
        </div>

        <div
          v-if="categories.length"
          class="section filter-list"
          :class="{ 'open': categoryOpen }"
        >
          <button
            v-b-toggle.collapse-list-category
            class="expand-btn"
            data-testid="categoryFilterToggleButton"
          >
            <h3
              class="section-label"
            >
              Category Filter
            </h3>
          </button>

          <b-collapse
            id="collapse-list-category"
            v-model="categoryOpen"
          >
            <content-filter-list
              :filter-list="categories"
              filter-type="categories"
              data-testid="categoriesFilterList"
              @change="onFilterChange"
            />
          </b-collapse>
        </div>
      </b-col>
    </b-row>

    <b-row class="ctf-tablet">
      <b-col>
        <b-row>
          <b-col>
            <custom-button
              v-b-modal.modal-result-filter
              class="filter-btn"
              label="Filter"
              secondary
            />
            <span class="results-count">{{ resultCount }} results</span>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <content-filter-tags
              :selected-types="selectedTypes"
              @dismiss="dismissType"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <content-filter-modal
      :content-types="contentTypes"
      :categories="categories"
      :clear-filters="clearFilters"
    />
  </b-container>
</template>

<script>
import {
  VBToggle,
  VBModal,
  BCollapse,
  BContainer,
  BRow,
  BCol
} from 'bootstrap-vue'
import CustomButton from '../../components/atoms/CustomButton'
import ContentFilterList from './ContentFilterList'
import ContentFilterTags from '../../components/molecules/ContentFilterTags'
import ContentFilterModal from './ContentFilterModal'
import { numberToLocaleString } from '../../utils/FormatData'

export default {
  name: 'ContentFilter',
  components: {
    BCollapse,
    BContainer,
    BRow,
    BCol,
    CustomButton,
    ContentFilterList,
    ContentFilterTags,
    ContentFilterModal
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal
  },
  props: {
    contentTypes: {
      type: Array,
      required: false,
      default: () => [],
      validator: function (contentTypes) {
        const keys = ['label', 'count', 'selected']

        return contentTypes.every(type => keys.every(key => key in type))
      }
    },
    categories: {
      type: Array,
      required: false,
      default: () => [],
      validator: function (categories) {
        const keys = ['label', 'count', 'selected']

        return categories.every(type => keys.every(key => key in type))
      }
    }
  },
  data: function () {
    return {
      contentTypeOpen: true,
      categoryOpen: true
    }
  },
  computed: {
    resultCount: function () {
      const total = [...this.contentTypes, ...this.categories].reduce((a, b) => {
        if (b.selected || !this.selectedTypes.length) {
          return a + b.count
        } else return a
      }, 0)

      return numberToLocaleString(total)
    },
    selectedTypes: function () {
      const contentTypesSelected = this.contentTypes
        .map((type, index) => ({ ...type, index, filterType: 'contentTypes' }))
        .filter(type => type.selected)
      const categoriesSelected = this.categories
        .map((type, index) => ({ ...type, index, filterType: 'categories' }))
        .filter(type => type.selected)
      return [...contentTypesSelected, ...categoriesSelected]
    }
  },
  methods: {
    dismissType: function ({ filterType, index }) {
      // eslint-disable-next-line
      this[filterType][index].selected = false
    },
    clearFilters: function () {
      this.contentTypes.forEach(type => { type.selected = false })
      this.categories.forEach(type => { type.selected = false })
    },
    onFilterChange: function ({ filterType, index, value }) {
      this[filterType][index].selected = value
    }
  }
}
</script>

<style lang="scss">
.content-filter {
  .section {
    border-top: 1pt solid black;
    display: flex;
    flex-direction: column;
  }

  .section-label {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1;
    margin: 24px 0;
  }

  .filters-info {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .results-count,
  .filters-info .clear-filters-btn {
    font-size: 0.875rem;
    line-height: 1;
    margin-top: 24px;
  }

  .results-count {
    margin-right: 24px;
  }

  .filters-info .clear-filters-btn {
    border: none;
    background: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    text-decoration: underline;
    text-align: right;
    transition: ease-out 0.3s;

    &:hover,
    &:focus-visible {
      color: $grey-3;
      transition: ease-out 0.3s;
    }
  }

  .section.filter-results .section-label {
    margin-bottom: 0;
  }

  .section.filter-list {
    &:last-child {
      border-bottom: 1pt solid black;
    }

    .section-label {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        width: 14px;
        height: 14px;
        mask-image: url(../../assets/svg/chevron.svg);
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        background-color: black;
        margin-right: 16px;
        transform: rotate(270deg);
        transition: ease-out 0.3s;
      }
    }

    &.open .section-label::before {
      transform: rotate(90deg);
      transition: ease-out 0.3s;
    }

    .expand-btn {
      border: none;
      background: none;
      outline: none;
      padding: 0;
      cursor: pointer;

      &:hover,
      &:focus-visible {
        .section-label {
          text-decoration: underline;
        }
      }
    }
  }
}

.filter-btn {
  width: 154px;
  height: 40px;
  background-color: $grey-1;
  border: 1pt solid $grey-2;
  box-shadow: none;
  outline: none;

  &:focus {
    outline: none;
  }
}

.row {
  &.ctf-tablet {
    display: none;
  }

  @media screen and (max-width: $breakpoint-md-max) {
    &.ctf-desktop {
      display: none;
    }

    &.ctf-tablet {
      display: flex;

      .row {
        align-items: center;
      }
    }

    .results-count {
      padding: 16px 0;
      margin-right: 0;
      display: block;
      border-top: 1px solid $grey-6;
      border-bottom: 1px solid $grey-6;
    }

    .results-count,
    .clear-filters-btn {
      margin-top: 0;
    }
  }

  @media screen and (max-width: $breakpoint-sm-max) {
    &.ctf-tablet {
      .row {
        flex-direction: column;
        align-items: flex-start;
      }

      .results-count {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}
</style>
