<template>
  <b-row class="content-preview-row">
    <b-col>
      <div class="content-preview">
        <router-link
          :to="category.path"
          class="content-category b1"
          data-testid="contentPreviewCategoryLink"
        >
          {{ category.name }}
        </router-link>

        <router-link
          :to="path"
          class="content-title h3"
          data-testid="contentPreviewLink"
        >
          {{ title }}
        </router-link>

        <p
          class="content-subtitle b1"
          data-testid="contentPreviewUpdatedDate"
        >
          {{ type }} / Updated {{ formattedDate }}
        </p>

        <p class="content-description b1">
          {{ description }}
        </p>

        <router-link
          :to="path"
          class="content-link b1"
          data-testid="contentPreviewVideoLink"
        >
          {{ type === 'Video' ? 'Watch Video' : `View ${type}` }}
        </router-link>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { dateStringTommddyyyy, dateStringValidator } from '../../utils/DateTime'

export default {
  name: 'ContentPreview',
  components: {
    BRow,
    BCol
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['Article', 'Cheat Sheet', 'Step-By-Step', 'Video'].includes(value)
      }
    },
    category: {
      type: Object,
      required: true,
      validator: function (category) {
        return ['name', 'path'].every(key => key in category)
      }
    },
    title: {
      type: String,
      required: true
    },
    updatedAt: {
      type: String,
      required: true,
      validator: dateStringValidator
    },
    description: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedDate: function () {
      return dateStringTommddyyyy(this.updatedAt)
    }
  }
}
</script>

<style lang="scss" scoped>
.content-preview {
  padding: 35px 0 38px;
  display: flex;
  flex-direction: column;

  .content-category {
    color: $red-1;
    font-weight: 600;
  }

  .content-subtitle {
    font-style: italic;
    color: $grey-3;
  }

  .content-title,
  .content-description,
  .content-link {
    color: $black;
  }

  .content-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    &::after {
      content: '...';
    }
  }

  .content-category,
  .content-title,
  .content-subtitle,
  .content-description {
    margin-bottom: 14px;
  }

  .content-link {
    text-decoration: underline;

    &:hover,
    &:focus-visible {
      color: $grey-3;
    }
  }

  .content-category,
  .content-title {
    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: $breakpoint-md-max) {
    margin-left: 0;
  }

  @media screen and (max-width: $breakpoint-sm-max) {
    padding: 28px 0 32px;
  }
}

.content-preview-row {
  .content-preview {
    border-top: 1pt solid $grey-6;
  }

  &:last-child .content-preview {
    border-bottom: 1pt solid $grey-6;
  }
}
</style>
