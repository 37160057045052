<template>
  <div
    v-if="selectedTypes.length"
    class="content-filter-tags"
  >
    <button
      v-for="(type, index) in selectedTypes"
      :key="`selectedType#${index}`"
      class="type-tag"
      :aria-label="`Dismiss ${type} filter`"
      @click="onClick(type.filterType, type.index)"
    >
      {{ type.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ContentFilterTags',
  props: {
    selectedTypes: {
      type: Array,
      required: true,
      validator: function (selectedTypes) {
        const keys = ['filterType', 'label', 'index']

        return selectedTypes.every(type => keys.every(key => key in type))
      }
    }
  },
  methods: {
    onClick: function (filterType, index) {
      this.$emit('dismiss', { filterType, index })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-filter-tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;

  .type-tag {
    border: none;
    background: none;
    outline: none;
    padding: 0 8px;
    margin: 0 8px 8px 0;
    height: 32px;
    display: flex;
    align-items: center;
    background-color: $grey-2;
    transition: ease-out 0.3s;
    font-size: 0.75rem;
    white-space: nowrap;
    cursor: pointer;

    &::after {
      content: '';
      width: 8.5px;
      height: 8.5px;
      background-color: $black;
      mask-image: url(../../assets/svg/close.svg);
      mask-repeat: no-repeat;
      mask-size: contain;
      margin-left: 8px;
    }

    &:hover,
    &:focus-within {
      background-color: $grey-1;
      transition: ease-out 0.3s;
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .content-filter-tags {
    padding: 16px 0 8px;
    border-bottom: 1px solid $grey-6;
    width: 100%;
    margin-bottom: 0;
  }
}
</style>
