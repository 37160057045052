<template>
  <article class="detail-template-primary">
    <b-container>
      <b-row>
        <b-col>
          <breadcrumbs
            :items="primaryData.breadcrumbs"
            :article-title="primaryData.mainItem.title"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="header-block-wraper">
          <header-block
            :type="primaryData.mainItem.mainType"
            :title="primaryData.mainItem.title"
            :description="primaryData.mainItem.description"
            :background-image-url="primaryData.mainItem.image.src"
            :without-c-t-a="true"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="result-block-wraper">
          <result-block-primary
            :result-count="primaryData.resultDetails.resultCount"
            :current-page="1"
            default-selected="newest"
            @showFilter="showFilter"
          >
            <template #heading>
              <span>Articles From</span> {{ primaryData.mainItem.title }}
            </template>
          </result-block-primary>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="3"
          sm="12"
        >
          <section class="filter-block-wraper">
            <content-filter
              :content-types="primaryData.contentTypes"
            />
          </section>
        </b-col>
        <b-col
          lg="9"
          sm="12"
          class="result-list-wraper"
        >
          <content-preview
            v-for="(category, i) of primaryData.categories"
            :key="`${i}contentList`"
            :type="category.type"
            :category="category.category"
            :title="category.title"
            :updated-at="category.updatedAt"
            :description="category.description"
            :path="category.path"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="bottom-pagination-wraper">
          <pagination-custom
            :current-page="1"
            :total-pages="primaryData.resultDetails.resultCount / 10"
            direction="top"
            class="float-right"
          />
        </b-col>
      </b-row>
    </b-container>
  </article>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import Breadcrumbs from '../molecules/Breadcrumbs'
import HeaderBlock from '../organisms/HeaderBlock'
import ResultBlockPrimary from '../../components/organisms/ResultBlockPrimary'
import ContentPreview from '../../components/organisms/ContentPreview'
import ContentFilter from '../../components/organisms/ContentFilter'
import paginationCustom from '../molecules/PaginationCustom'

export default {
  name: 'DetailTemplatePrimary',
  components: {
    BContainer,
    BRow,
    BCol,
    Breadcrumbs,
    HeaderBlock,
    ResultBlockPrimary,
    ContentPreview,
    ContentFilter,
    paginationCustom
  },
  props: {
    primaryData: {
      type: Object,
      required: true
    }
  },
  methods: {
    showFilter () {
      this.$bvModal.show('modal-result-filter')
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-template-primary {
  >.container {
    padding: 30px;
    max-width: $pg-max-width;
    height: inherit;

    .header-block-wraper {
      padding-top: 48px;
    }

    .result-block-wraper {
      padding-top: 40px;
      padding-bottom: 4px;
    }

    .filter-block-wraper {
      padding-right: 16px;
    }

    .bottom-pagination-wraper {
      padding-top: 16px;
    }
  }
}

@media only screen and (max-width: $breakpoint-md-max) {
  .detail-template-primary {
    >.container {
      padding: 18px;
      max-width: $pg-max-width;
      height: inherit;

      .header-block-wraper {
        padding-top: 35px;

        /deep/ {
          .header-block {
            border-bottom: 1px solid $black;
          }
        }
      }

      .result-block-wraper {
        padding-top: 35px;
        padding-bottom: 0;
      }

      .filter-block-wraper {
        padding-right: 0;

        /deep/ {
          .filter-btn {
            display: none;
          }
        }
      }

      .result-list-wraper {
        /deep/ {
          .content-preview-row {
            &:first-child {
              .content-preview {
                border-top: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .detail-template-primary {
    >.container {
      .result-block-wraper {
        padding-bottom: 0;
      }

      .filter-block-wraper {
        margin-top: -20px;
      }
    }
  }
}
</style>
