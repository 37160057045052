<template>
  <detail-template-primary :primary-data="primaryData" />
</template>

<script>
import DetailTemplatePrimary from '../components/templates/DetailTemplatePrimary'
// data should come from Vuex state while intergate with API
// Below import is temperary one and added for dev purpose
import collectionData from '../../mocks/collectionData.json'

export default {
  name: 'CollectionPage',
  components: {
    DetailTemplatePrimary
  },
  data: function () {
    return {
      primaryData: collectionData.data
    }
  }
}
</script>
