<template>
  <b-modal
    id="modal-result-filter"
    dialog-class="filter-modal"
    hide-backdrop
    data-testid="contentFilterModal"
  >
    <template #modal-header="{ close }">
      <div class="modal-header-wrapper">
        <h2
          class="modal-title"
          data-testid="contentFilterModalHead"
        >
          Filter
        </h2>

        <button
          class="close-modal-btn"
          @click="close()"
        />
      </div>
    </template>
    <div v-if="contentTypes.length">
      <h3
        class="section-title"
        data-testid="contentFilterModalSectionHead"
      >
        Content Type
      </h3>

      <content-filter-list
        :filter-list="contentTypes"
        filter-type="contentTypes"
      />
    </div>
    <div
      v-if="categories.length"
      class="pt-2"
    >
      <h3
        class="section-title"
        data-testid="categoryFilterModalSectionHead"
      >
        Category Filter
      </h3>

      <content-filter-list
        :filter-list="categories"
        filter-type="categories"
      />
    </div>
    <template #modal-footer="{ close }">
      <custom-button
        class="modal-footer-btn"
        label="Clear Filters"
        secondary
        :disabled="disableActions"
        data-testid="contentFilterModalClearButton"
        @click="clearFilters()"
      />

      <custom-button
        class="modal-footer-btn"
        label="Apply"
        primary
        :disabled="disableActions"
        data-testid="contentFilterModalDisableButton"
        @click="close()"
      />
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import CustomButton from '../../components/atoms/CustomButton'
import ContentFilterList from './ContentFilterList'

export default {
  name: 'ContentFilterModal',
  components: {
    BModal,
    CustomButton,
    ContentFilterList
  },
  props: {
    contentTypes: {
      type: Array,
      required: true,
      validator: function (contentTypes) {
        const keys = ['label', 'count', 'selected']

        return contentTypes.every(type => keys.every(key => key in type))
      }
    },
    categories: {
      type: Array,
      required: true,
      validator: function (categories) {
        const keys = ['label', 'count', 'selected']

        return categories.every(type => keys.every(key => key in type))
      }
    },
    clearFilters: {
      type: Function,
      required: true
    }
  },
  computed: {
    disableActions: function () {
      const selected = [...this.contentTypes.filter(type => type.selected), ...this.categories.filter(type => type.selected)]

      return !selected.length
    }
  }
}
</script>

<style lang="scss">
.filter-modal.modal-dialog {
  max-width: none;
  margin: 0;

  .modal-header {
    padding: 0 24px;
    border: none;
  }

  .modal-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 29px 0;
    border-bottom: 1px solid $grey-2;

    .close-modal-btn {
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      width: 24px;
      height: 24px;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      &::after {
        content: '';
        background-color: white;
        width: 8.5px;
        height: 8.5px;
        display: block;
        mask-image: url(../../assets/svg/close.svg);
        mask-position: center;
        mask-repeat: no-repeat;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .modal-title {
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 0;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    height: 100vh;
  }

  .modal-body {
    padding: 32px 24px 24px;
    overflow: auto;
  }

  .section-title {
    font-size: 1rem;
    font-weight: 900;
    line-height: 1;
    margin-bottom: 32px;
  }

  .modal-footer {
    justify-content: stretch;
    padding: 24px;
    border-color: $grey-2;
  }

  .modal-footer-btn {
    width: auto;
    flex: 1;
    box-shadow: none;
    margin: 0;

    &:first-child {
      margin-right: 24px;
    }

    &:disabled {
      border: 1pt solid $grey-2;
      background-color: $grey-1;
      color: $grey-3;
      cursor: default;
    }
  }

  @media screen and (max-width: $breakpoint-sm-max) {
    .modal-header-wrapper {
      padding: 20px 0;
    }

    .modal-header {
      padding: 0 16px;
    }

    .modal-body {
      padding: 24px 16px 16px;
    }

    .section-title {
      margin-bottom: 24px;
    }

    .modal-footer {
      padding: 16px;

      &:first-child {
        margin-right: 16px;
      }
    }
  }
}
</style>
